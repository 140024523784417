.filter_container{
    display: flex;
    flex-direction: row;
    /* justify-content: space-around; */
    align-items: center;
    background-color: #D7D8D6;
    padding-left: 40px;
    padding-right: 30px;
    padding-top: 14px;
}

.dropdown{
    margin: 15px ;
}

.inner_box{
    display: "flex";
    flex-direction: column;
}

.inner_box_label{
    margin: 5px;
     margin-left: 7px;
      padding: 10px;
}
.filter-back-white{
    background-color: #fff;
}
.net-size-width {
    width: 300px;
    margin-right: 20px;
    gap: 10px;
}
.inner_box_label{
    width: 100%;
    display: inline-block;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
}
.input-group-box{
    display: flex;
}
.input-group-box input{
    height: 35px;
    background-color: #fff;
    border: none;
    border-right: solid 1px #ced4da;
    border-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group-box .input-label{
    font-size: .875rem;
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.net-size-iiner input{
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.net-size-iiner .input-label{
    border-top-right-radius: 0rem;
    border-bottom-right-radius: 0rem;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.loginContainer{
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border: 1px solid black;
    border-radius: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 50px;
    box-shadow: 0px 0px 7px 1px lightslategray;
}